<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="5"
      >
        <v-text-field
          v-model="form.code"
          :rules="[v => !!v || 'Discount code is required']"
          label="Discount Code"
          outlined
          required
          dense
        />
      </v-col>
      <v-col
        cols="12"
        sm="5"
      >
        <v-text-field
          v-model="form.friendly_name"
          :rules="[v => !!v || 'Discount code name is required']"
          label="Discount Code Name"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="form.max_uses"
          label="Max Uses"
          outlined
          type="number"
          dense
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="form.min_spend"
          label="Min Spend"
          step="0.01"
          min="0"
          prefix="£"
          type="number"
          outlined
          dense
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="form.max_spend"
          label="Max Spend"
          step="0.01"
          min="0"
          prefix="£"
          type="number"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <v-divider/>

      <v-row class="mt-5">
        <v-col
          cols="12"
          sm="5"
        >
          <v-dialog
            ref="startsAtDialog"
            v-model="showStartsAtDialog"
            :return-value.sync="form.starts_at"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="$moment(form.starts_at).format('ddd Do MMM')"
                label="Valid From"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.starts_at"
              :min="$moment().format('YYYY-MM-DD')"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="showStartsAtDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.startsAtDialog.save(form.starts_at)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
          cols="12"
          sm="5"
        >
          <v-dialog
            ref="endsAtDialog"
            v-model="showendsAtDialog"
            :return-value.sync="form.expires_at"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="form.expires_at ? $moment(form.expires_at).format('ddd Do MMM') : null"
                label="Valid Until"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.expires_at"
              :min="$moment().format('YYYY-MM-DD')"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="showendsAtDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.endsAtDialog.save(form.expires_at)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

    <v-divider />

    <v-row>
      <v-col
        cols="12"
        sm="5"
      >
        <v-checkbox
          v-model="form.fixed"
          label="Fixed Discount?"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="!form.fixed"
        cols="12"
        sm="5"
      >
        <v-text-field
          v-model="form.percentage_amount"
          :rules="[v => !!v || 'Percentage amount is required']"
          label="Percentage Amount"
          step="0.01"
          min="0"
          prefix="%"
          outlined
          required
          dense
        />
      </v-col>
      <v-col
        v-if="form.fixed"      
        cols="12"
        sm="5"
      >
        <v-text-field
          v-model="form.fixed_amount"
          :rules="[v => !!v || 'Fixed amount is required']"
          label="Fixed Amount"
          step="0.01"
          min="0"
          prefix="£"
          outlined
          required
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'DiscountCodeFields',

  props: {
    form: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      showStartsAtDialog: false,
      showendsAtDialog: false
    }
  }
}
</script>