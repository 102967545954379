var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Add Discount Code "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v(" mdi-plus-circle-outline ")])],1),_c('DataTable',{attrs:{"cols":_vm.cols,"default-order-by":"created_at","path":"discount-codes"},scopedSlots:_vm._u([{key:"item.min_spend",fn:function(ref){
var code = ref.item;
return [(code.min_spend)?_c('span',[_vm._v(_vm._s(_vm.$currency(code.min_spend)))]):_c('span',[_vm._v("-")])]}},{key:"item.max_spend",fn:function(ref){
var code = ref.item;
return [(code.max_spend)?_c('span',[_vm._v(_vm._s(_vm.$currency(code.max_spend)))]):_c('span',[_vm._v("-")])]}},{key:"item.max_uses",fn:function(ref){
var code = ref.item;
return [(code.max_uses)?_c('span',[_vm._v(_vm._s(code.max_uses))]):_c('span',[_vm._v("-")])]}},{key:"item.starts_at",fn:function(ref){
var code = ref.item;
return [_vm._v(" "+_vm._s(_vm.$momentDate(code.starts_at || code.created_at))+" ")]}},{key:"item.expires_at",fn:function(ref){
var code = ref.item;
return [(code.expires_at)?_c('span',[_vm._v(_vm._s(_vm.$momentDate(code.expires_at)))]):_c('span',[_vm._v("-")])]}},{key:"item.actions",fn:function(){return undefined},proxy:true}])}),_c('AddDiscountCodeDialog',{attrs:{"dialog":_vm.dialog},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }