<template>
  <div>
    <v-btn
      class="mt-4"
      color="primary"
      @click="dialog = true"
    >
      Add Discount Code
      <v-icon
        small
        right
      >
        mdi-plus-circle-outline
      </v-icon>
    </v-btn>
    <DataTable
      :cols="cols"
      default-order-by="created_at"
      path="discount-codes"
    >
      <template v-slot:item.min_spend="{ item: code }">
        <span v-if="code.min_spend">{{ $currency(code.min_spend) }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.max_spend="{ item: code }">
        <span v-if="code.max_spend">{{ $currency(code.max_spend) }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.max_uses="{ item: code }">
        <span v-if="code.max_uses">{{ code.max_uses }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.starts_at="{ item: code }">
        {{ $momentDate(code.starts_at || code.created_at) }}
      </template>
      <template v-slot:item.expires_at="{ item: code }">
        <span v-if="code.expires_at">{{ $momentDate(code.expires_at) }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.actions>
        
      </template>
    </DataTable>
    <AddDiscountCodeDialog :dialog.sync="dialog" />
  </div>
</template>
<script>
import DataTable from '@/components/DataTable'
import AddDiscountCodeDialog from '@/components/discount-codes/AddDiscountCodeDialog'

export default {
  name: 'DiscountCodes',

  components: {
    DataTable,
    AddDiscountCodeDialog
  },

  data () {
    return {
      dialog: false,
      cols: [
        { text: 'Code', value: 'code' },
        { text: 'Only SKU', value: 'only_sku' },
        { text: 'Min Spend', value: 'min_spend' },
        { text: 'Max Spend', value: 'max_spend' },
        { text: 'Max Used', value: 'max_uses' },
        { text: 'Starts At', value: 'starts_at' },
        { text: 'Expires At', value: 'expires_at' },
        { text: 'Actions', value: 'actions' }
      ]
    }
  }
}
</script>