<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add Discount Code</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
          >
            <DiscountCodeFields :form="form" />

            <FormErrors ref="formErrors" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="closeDialog">Close</v-btn>
          <v-btn :loading="loading" color="primary" text @click="createVehicle">Add Discount Code <v-icon small right>mdi-plus-circle-outline</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBar success ref="successBar">
      Discount code created successfully!
    </SnackBar>
    <SnackBar error ref="errorBar">
      There was an issue creating the discount code.
    </SnackBar>
  </div>
</template>
<script type="text/javascript">
import HasDialog from '@/mixins/HasDialog'
import HasForm from '@/mixins/HasForm'
import DiscountCodeFields from '@/components/discount-codes/DiscountCodeFields'
import SnackBar from '@/components/SnackBar'
import FormErrors from '@/components/FormErrors'

export default {
  name: 'AddDiscountCodeDialog',

  mixins: [ HasDialog, HasForm ],

  components: {
    DiscountCodeFields,
    SnackBar,
    FormErrors
  },

  data () {
    return {
      loading: false,
      form: {
        fixed: false
      }
    }
  },

  methods: {
    createVehicle () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$refs.formErrors.clear()
        this.$api.persist('post', {
          path: 'discount-codes',
          object: this.form
        })
          .then(() => {
            this.$refs.successBar.open()
            this.closeDialog()
          })
          .catch(error => {
            this.$refs.formErrors.setErrors(error)
            this.$refs.errorBar.open()
          })
          .finally(() => { this.loading = false })
      }
    }
  }
}
</script>
